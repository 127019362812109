<template>
  <div class="partners-footer text-center d-flex flex-row justify-center mt-10">
    <v-sheet class="mx-auto" max-width="95%">
      <v-slide-group ref="slideGroup" show-arrows="always">
        <v-slide-item v-for="(src, i) in imgs" :key="i" class="min-width-fixed">
          <div>
            <img
              @load="updateSliderWidth()"
              :src="src"
              height="50px"
              :alt="'partner ' + i"
            />
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
// TODO: properly fix the problem of missing arrows!
// Explanation: v-slide-group decides whether or not to display arrows based on the width of its elements.
// However, in the case of images, their width is not known until they are loaded.
// This leads the container to think that the arrows are useless.
// The "min-width-fixed" class is a workaround for setting the minimum width of all elements.
// However, it is not a robust solution.

export default {
  data() {
    return {
      imgs: [],
    };
  },

  watch: {
    imgs: function () {
      this.updateSliderWidth();
    },
  },

  created() {
    this.updateSliderWidth();
  },

  mounted() {
    this.importAll(
      require.context(
        "@/assets/images/partners/",
        true,
        /\.png$|\.jpg$|\.jpeg$/
      )
    );

    this.updateSliderWidth();
  },

  updated() {
    this.updateSliderWidth();
  },

  methods: {
    importAll(r) {
      r.keys().forEach((key) => {
        this.imgs.push(r(key));
      });
    },

    updateSliderWidth() {
      // a workaround to activate slider arrows
      if (null != this.$refs.slideGroup) {
        this.$refs.slideGroup.setWidths();
        this.$refs.slideGroup.$forceUpdate();
      }
    },
  },
};
</script>


<style scoped>
.partners-footer {
  padding-top: 2em;
  padding-bottom: 1.5em;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
}
.partners-footer img {
  padding: 0em 1em;
}

.min-width-fixed {
  min-width: 100px;
}
</style>